@keyframes Floatingx {
  from { -webkit-transform: translate(50px, 0px) }
  65% { -webkit-transform: translate(0, 0) }
  to { -webkit-transform: translate(50px, -0px) }
}

@keyframes Floatingy {
  from { -webkit-transform: translate(0, 0px) }
  65% { -webkit-transform: translate(50px, 0) }
  to { -webkit-transform: translate(0, -0px) }
}

@keyframes gradient {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}